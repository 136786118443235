import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import registerIllustration from '../assets/img/illustrations/auth-register-illustration-light.png';
import bgShapeImage from '../assets/img/illustrations/bg-shape-image-light.png';
import ellayalogo from '../assets/logo/Ellaya_Logo-01-transparent.png';

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        terms: false
    });
    
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Reset errors
    
        // Basic client-side validation
        const newErrors = {};
        if (!formData.username) newErrors.username = 'Username is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!formData.password) newErrors.password = 'Password is required';
        if (!formData.passwordConfirmation) newErrors.passwordConfirmation = 'Password confirmation is required';
        else if (formData.password !== formData.passwordConfirmation) newErrors.passwordConfirmation = 'Passwords do not match';
        if (!formData.terms) newErrors.terms = 'You must agree to the terms';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        try {
            // Make the API call using fetch
            const response = await fetch('https://ellaya3.codebuddys.in/api/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': getCsrfToken() // Add CSRF token if required
                },
                body: JSON.stringify({
                    username: formData.username,
                    email: formData.email,
                    password: formData.password,
                    password_confirmation: formData.passwordConfirmation,
                    terms: formData.terms
                })
            });
    
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'An error occurred');
                }
                console.log('Signup successful:', data);
                navigate('/'); // Navigate to login page on success
            } else {
                const text = await response.text();
                console.error('Unexpected response format:', text);
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error('Signup error:', error.message);
            setErrors({ general: error.message });
        }
    };
    
    

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    // Function to get CSRF token from meta tag
    const getCsrfToken = () => {
        const meta = document.querySelector('meta[name="csrf-token"]');
        return meta ? meta.getAttribute('content') : '';
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <div className="p-3 ms-5 logo-div">
                <img src={ellayalogo} alt="Ellaya Logo" className="my-5 logo-css" />
            </div>
            <div className="authentication-inner row m-0">
                {/* Left Text */}
                <div className="d-none d-lg-flex col-lg-8 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img
                            src={registerIllustration}
                            alt="Register Illustration"
                            className="my-5 auth-illustration"
                        />
                        <img
                            src={bgShapeImage}
                            alt="Background Shape"
                            className="platform-bg"
                        />
                    </div>
                </div>

                {/* Register */}
                <div className="d-flex col-12 col-lg-4 align-items-center authentication-bg p-sm-12 p-6">
                    <div className="w-px-400 mx-auto mt-12 pt-5">
                        <h4 className="mb-1">Adventure starts here 🚀</h4>
                        <p className="mb-6">Make your app management easy and fun!</p>

                        <form onSubmit={handleSubmit} id="formAuthentication" className="mb-6">
                            <div className="mb-6">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder="Enter your username"
                                    autoFocus
                                />
                                {errors.username && <div className="text-danger">{errors.username}</div>}
                            </div>

                            <div className="mb-6">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>

                            <div className="mb-6 form-password-toggle">
                                <label className="form-label" htmlFor="password">Password</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        className="form-control"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="********"
                                        aria-describedby="password"
                                    />
                                    <span
                                        className="input-group-text cursor-pointer"
                                        id="togglePassword"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className={`ti ${showPassword ? 'ti-eye' : 'ti-eye-off'}`}></i>
                                    </span>
                                </div>
                                {errors.password && <div className="text-danger">{errors.password}</div>}
                            </div>

                            <div className="mb-6">
                                <label className="form-label" htmlFor="passwordConfirmation">Confirm Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="passwordConfirmation"
                                    className="form-control"
                                    name="passwordConfirmation"
                                    value={formData.passwordConfirmation}
                                    onChange={handleChange}
                                    placeholder="********"
                                    aria-describedby="passwordConfirmation"
                                />
                                {errors.passwordConfirmation && <div className="text-danger">{errors.passwordConfirmation}</div>}
                            </div>

                            <div className="mb-6 mt-8">
                                <div className="form-check mb-8 ms-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="terms-conditions"
                                        name="terms"
                                        checked={formData.terms}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="terms-conditions">
                                        I agree to <a href="#">privacy policy & terms</a>
                                    </label>
                                </div>
                                {errors.terms && <div className="text-danger">{errors.terms}</div>}
                            </div>
                            <button type="submit" className="btn btn-primary d-grid w-100">Sign up</button>
                        </form>

                        <p className="text-center">
                            <span>Already have an account?</span>
                            <a href="/">
                                <span>Sign in instead</span>
                            </a>
                        </p>

                        <div className="divider my-6">
                            <div className="divider-text">or</div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <a href="#" className="btn btn-sm btn-icon rounded-pill btn-text-facebook me-1_5">
                                <i className="tf-icons ti ti-brand-facebook-filled"></i>
                            </a>
                            <a href="#" className="btn btn-sm btn-icon rounded-pill btn-text-twitter me-1_5">
                                <i className="tf-icons ti ti-brand-twitter-filled"></i>
                            </a>
                            <a href="#" className="btn btn-sm btn-icon rounded-pill btn-text-github me-1_5">
                                <i className="tf-icons ti ti-brand-github-filled"></i>
                            </a>
                            <a href="#" className="btn btn-sm btn-icon rounded-pill btn-text-google-plus">
                                <i className="tf-icons ti ti-brand-google-filled"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
