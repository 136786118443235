import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import loginIllustration from '../assets/img/illustrations/auth-login-illustration-light.png';
import bgShapeImage from '../assets/img/illustrations/bg-shape-image-light.png';
import ellayalogo from '../assets/logo/Ellaya_Logo-01-transparent.png';

// Validation schema using Yup
const validationSchema = Yup.object({
  usernameOrEmail: Yup.string()
    .required('Username or Email is required'),
  password: Yup.string()
    .required('Password is required'),
});

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  };

  const handleLogin = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await fetch('https://ellaya3.codebuddys.in/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        // Extract error details
        const errorText = await response.text();
        let errorMessage = 'An unexpected error occurred';

        // Check for known error responses
        if (response.status === 401) {
          const errorData = JSON.parse(errorText);

          // Customize error messages based on the server response
          if (errorData.message === 'Invalid username/email or password') {
            errorMessage = 'Invalid username/email or password';
          } else {
            errorMessage = 'Data not found';
          }
        }

        throw new Error(errorMessage);
      }

      const result = await response.json();

      if (result.success) {
        navigate('/dashboard/analytics'); // Redirect after successful login
      } else {
        // Handle unexpected server responses
        setErrors({ api: result.message || 'An unexpected error occurred' });
      }
    } catch (error) {
      setErrors({ api: error.message || 'An unexpected error occurred' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      <div className="p-3 ms-5 logo-div">
        <img src={ellayalogo} alt="Ellaya Logo" className="my-5 logo-css" />
      </div>
      <div className="authentication-inner row m-0">
        <div className="d-none d-lg-flex col-lg-8 p-0">
          <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
            <img
              src={loginIllustration}
              alt="Login Illustration"
              className="my-5 auth-illustration"
            />
            <img
              src={bgShapeImage}
              alt="Background Shape"
              className="platform-bg"
            />
          </div>
        </div>
        <div className="d-flex col-12 col-lg-4 align-items-center authentication-bg p-sm-12 p-6">
          <div className="w-px-400 mx-auto mt-12 pt-5">
            <h4 className="mb-1">Welcome to Ellaya 👋</h4>
            <p className="mb-6">Please sign in to your account and start the adventure</p>

            <Formik
              initialValues={{ usernameOrEmail: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({ isSubmitting, errors }) => (
                <Form id="formAuthentication" className="mb-6">
                  {errors.api && <div className="alert alert-danger">{errors.api}</div>}
                  <div className="mb-6">
                    <label htmlFor="usernameOrEmail" className="form-label">Username or Email</label>
                    <Field
                      type="text"
                      className="form-control"
                      id="usernameOrEmail"
                      name="usernameOrEmail"
                      placeholder="Enter your username or email"
                      autoFocus
                    />
                    <ErrorMessage name="usernameOrEmail" component="div" className="text-danger" />
                  </div>
                  <div className="mb-6 form-password-toggle">
                    <label className="form-label" htmlFor="password">Password</label>
                    <div className="input-group input-group-merge">
                      <Field
                        type={passwordVisible ? 'text' : 'password'}
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="Enter your password"
                      />
                      <span
                        className="input-group-text cursor-pointer"
                        id="togglePassword"
                        onClick={togglePasswordVisibility}
                      >
                        <i className={`ti ${passwordVisible ? 'ti-eye' : 'ti-eye-off'}`}></i>
                      </span>
                    </div>
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>
                  <div className="my-8">
                    <div className="d-flex justify-content-between">
                      <div className="form-check mb-0 ms-2">
                        <Field type="checkbox" className="form-check-input" id="remember-me" name="rememberMe" />
                        <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                      </div>
                  
                      <a href="">
                      <Link to={"/forgotpassword"}>
                        <p className="mb-0">Forgot Password?</p>
                        </Link>
                      </a>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary d-grid w-100" disabled={isSubmitting}>
                    {isSubmitting ? 'Signing in...' : 'Sign in'}
                  </button>
                </Form>
              )}
            </Formik>

            <p className="text-center">
              <span>New on our platform? </span>
              <a href="/signup">
                <span>Create an account</span>
              </a>
            </p>

            <div className="divider my-6">
              <div className="divider-text">or</div>
            </div>

            <div className="d-flex justify-content-center">
              <a href="javascript:;" className="btn btn-sm btn-icon rounded-pill btn-text-facebook me-1_5">
                <i className="tf-icons ti ti-brand-facebook-filled"></i>
              </a>
              <a href="javascript:;" className="btn btn-sm btn-icon rounded-pill btn-text-twitter me-1_5">
                <i className="tf-icons ti ti-brand-twitter-filled"></i>
              </a>
              <a href="javascript:;" className="btn btn-sm btn-icon rounded-pill btn-text-github me-1_5">
                <i className="tf-icons ti ti-brand-github-filled"></i>
              </a>
              <a href="javascript:;" className="btn btn-sm btn-icon rounded-pill btn-text-google-plus">
                <i className="tf-icons ti ti-brand-google-filled"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
