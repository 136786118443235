import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import bgShapeImage from '../assets/img/illustrations/bg-shape-image-light.png';
import forgot from '../assets/img/illustrations/auth-forgot-password-illustration-light.png';
import { useNavigate } from 'react-router-dom';
import ellayalogo from '../assets/logo/Ellaya_Logo-01-transparent.png';

// Define validation schema using Yup
const schema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Email is required'),
});

// Arrow function component
const ForgotPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://ellaya3.codebuddys.in/api/send-reset-link', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            alert('Password reset link sent successfully!');
            navigate('/');
        } catch (error) {
            alert('Failed to send reset link. Please try again later.');
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <div className="p-3 ms-5 logo-div">
                <img src={ellayalogo} alt="Ellaya Logo" className="my-5 logo-css" />
            </div>
            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-8 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img
                            src={forgot}
                            alt="auth-forgot-password-cover"
                            className="my-5 auth-illustration d-lg-block d-none"
                        />
                        <img
                            src={bgShapeImage}
                            alt="auth-forgot-password-cover"
                            className="platform-bg"
                        />
                    </div>
                </div>

                <div className="d-flex col-12 col-lg-4 align-items-center authentication-bg p-sm-12 p-6">
                    <div className="w-px-400 mx-auto mt-12 mt-5">
                        <h4 className="mb-1">Forgot Password? 🔒</h4>
                        <p className="mb-6">Enter your email and we'll send you instructions to reset your password</p>

                        <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
                            {errors.email && (
                                <div className="alert alert-danger">
                                    {errors.email.message}
                                </div>
                            )}

                            <div className="mb-6">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    {...register('email')}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary d-grid w-100">Send Reset Link</button>
                        </form>

                        <div className="text-center">
                            <a href="/" className="d-flex align-items-center justify-content-center">
                                <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                                Back to login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
