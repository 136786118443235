import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import bgShapeImage from '../assets/img/illustrations/bg-shape-image-light.png';
import reset from '../assets/img/illustrations/auth-reset-password-illustration-light.png';
import ellayalogo from '../assets/logo/Ellaya_Logo-01-transparent.png';

// Validation schema
const schema = yup.object().shape({
    password: yup.string().required('New password is required'),
    password_confirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required')
});

const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://ellaya3.codebuddys.in/api/password/reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: new URLSearchParams(window.location.search).get('email'),
                    password: data.password,
                    password_confirmation: data.password_confirmation
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to reset password');
            }

            alert('Password has been reset successfully!');
            navigate('/');
        } catch (error) {
            alert('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <div className="p-3 ms-5 logo-div">
                <img src={ellayalogo} alt="Ellaya Logo" className="my-5 logo-css" />
            </div>
            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-8 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img
                            src={reset}
                            alt="auth-reset-password-cover"
                            className="my-5 auth-illustration"
                        />
                        <img
                            src={bgShapeImage}
                            alt="auth-reset-password-cover"
                            className="platform-bg"
                        />
                    </div>
                </div>

                <div className="d-flex col-12 col-lg-4 align-items-center authentication-bg p-6 p-sm-12">
                    <div className="w-px-400 mx-auto mt-12 pt-5">
                        <h4 className="mb-1">Reset Password 🔒</h4>
                        <p className="mb-6">
                            <span className="fw-medium">Your new password must be different from previously used passwords</span>
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
                            <input type="hidden" name="email" value={new URLSearchParams(window.location.search).get('email')} />

                            <div className="mb-6 form-password-toggle">
                                <label className="form-label" htmlFor="password">New Password</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        type="password"
                                        id="password"
                                        className="form-control"
                                        placeholder="••••••••••••"
                                        {...register('password')}
                                    />
                                    <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                </div>
                                {errors.password && <div className="alert alert-danger">{errors.password.message}</div>}
                            </div>

                            <div className="mb-6 form-password-toggle">
                                <label className="form-label" htmlFor="confirm-password">Confirm Password</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        type="password"
                                        id="confirm-password"
                                        className="form-control"
                                        placeholder="••••••••••••"
                                        {...register('password_confirmation')}
                                    />
                                    <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                </div>
                                {errors.password_confirmation && <div className="alert alert-danger">{errors.password_confirmation.message}</div>}
                            </div>

                            <button type="submit" className="btn btn-primary d-grid w-100 mb-6" style={{
                                boxShadow: '0 0.125rem 0.375rem 0',
                                background: '#7367F0',
                                color: 'white'
                            }}>
                                Set new password
                            </button>

                            <div className="text-center">
                                <a href="/">
                                    <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                                    Back to login
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
